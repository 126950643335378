<template>
  <crew/>
</template>
<script>
import Crew from '@/components/Crew/Crew';

export default {
  components: {Crew},
  metaInfo() {
    return {
      title:
          'Zjazd FUT 2021 na Politechnice Poznańskiej - szoleniowcy',
    };
  },
};
</script>
